<template>
  <!-- <VueDocPreview value="src/assets/resume.docx" type="office" /> -->
  <div></div>
</template>

<script>
// import VueDocPreview from "vue-doc-preview";

export default {
  data: function() {
    return {};
  },
  components: {
    // VueDocPreview,
  },
};
</script>

<style scoped></style>
